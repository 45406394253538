export interface APIProperty {
  id: number;
  name: string;
  cin: string;
  isBookable: boolean;
  guestInteraction: "formal" | "informal";
  description?: string;
  email?: string;
  phone?: string;
  address: {
    street?: string;
    postalCode?: string;
    municipality?: string;
  };
  geolocation?: {
    latitude: number;
    longitude: number;
  };
  seasonImages?: Image[];
  galleryImages?: Image[];
  amenities?: Amenity[];
}

export interface Image {
  url: string;
  title?: string;
  width: number;
  height: number;
}

export interface Amenity {
  id: number;
  title: string;
}

export const languages = <const>["de", "it", "en"];

export type Language = (typeof languages)[number];
